.lbd-map {
  height: 60vh;
  width: 100%;
}

.lbd-marker {
  position: absolute;
  border-radius: 50%;
  border: 8px solid #000;
  width: 8px;
  height: 8px;
  cursor: pointer;
  z-index: 10
}

.lbd-marker::after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  bottom: -30px;
  left: -6px;
  border: 10px solid transparent;
  border-top: 17px solid #000;
}

.lbd-info-window img {
  width: 100%;
  height: auto;
  max-width: 300px;
}

.lbd-info-window-title {
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0;
}

.table {
  border-collapse: collapse;
  margin-bottom: 0;
}

.table tr {
  border: 1px solid #ccc;
}

.table td {
  padding: 5px 10px;
}

.detail-button {
  border-radius: 4px;
  font-size: 16px;
  padding: 8px 12px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #000;
  display: inline-block;
}